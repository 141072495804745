@font-face {
  font-family: Roboto;
  src: url("/fonts/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: url('/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Soleil;
  src: url("/fonts/SoleilRegular.otf");
  font-weight: 500; /* Medium */
  font-display: swap;
}
@font-face {
  font-family: Soleil;
  src: url("/fonts/SoleilLight.otf");
  font-weight: 300; /* Medium */
  font-display: swap;
}
@font-face {
  font-family: Soleil;
  src: url("/fonts/SoleilBook.otf");
  font-weight: 600; /* Bold */
  font-display: swap;
}

@font-face {
  font-family: Soleil;
  src: url("/fonts/SoleilBold.otf");
  font-weight: 700; /* Bold */
  font-display: swap;
}

body {
  font-family: Roboto, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Soleil, sans-serif;
}
